import axios from 'axios'
import React from 'react'

export const DashboardServiceContext = React.createContext()

class DashboardService {

    constructor() { }

    // Odata Controller
    getShopifyHourlyIntegration() {
        return axios.get(process.env.REACT_APP_DASHBOARD_API_URL + '/Integrations/HourlyAPIReceive')
    }

}

export default DashboardService